

























import vue, {PropType} from 'vue';
import InstructionModel from '@/models/instruction.model';
import AnswerModel from '@/models/answer.model';
import Message from '@/components/messages/Message.vue';
import ChatMessageModel from '@/models/chat-message.model';
import createChatStream from '@/factories/chat-stream.factory';
import ChatMessage from '@/components/chat/ChatMessage.vue';
import features from '@/helpers/features';

export default vue.extend({
    components: {
        ChatMessage,
        Message,
    },
    props: {
        instructions: {
            type: Array as PropType<InstructionModel[]>,
            required: true,
        },
        answers: {
            type: Array as PropType<AnswerModel[]>,
            required: true,
        },
    },
    watch: {
        messages(stream: ChatMessageModel[]): void {
            this.$emit('messages', stream);
        },
    },
    computed: {
        messages(): ChatMessageModel[] {
            return createChatStream(
                this.instructions,
                this.answers,
                this.$i18n.locale,
            );
        },
        streamAnimations(): Array<string> {
            const animations = ['fade', 'scale'];
            const {canAnimateHeight} = features;

            if (canAnimateHeight) {
                animations.push('grow');
            }

            return animations;
        },
        streamStyle(): object {
            const {streamAnimations} = this;

            return {
                '--stream-animation': streamAnimations.join(', '),
            };
        },
    },
    methods: {
        onAnimationStart(): void {
            this.$emit('animation-start');
        },
        onAnimationEnd(): void {
            this.$emit('animation-end');
        },
        isLatest(message: ChatMessageModel): boolean {
            const {messages: stream} = this;
            return message.uuid === stream[stream.length - 1].uuid;
        },
    },
});
