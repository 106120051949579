




// @ts-nocheck
import vue from 'vue';
import ShortCodeApi from '@/apis/shortcode.api';

export default vue.extend({
    name: 'ShortCode',
    created() {
        const {shortCode} = this.$route.params;
        ShortCodeApi
            .resolve(shortCode)
            .then(({
                url, community, script, conversation, user,
            }) => {
                if (url) {
                    this.$router.push(url);
                    return;
                }

                const shortCodeData = this.$cookies.get('data');
                if (shortCodeData && shortCodeData[shortCode]) {
                    const shortCodeDataConversation = shortCodeData[shortCode].conversation;
                    const shortCodeDataCommunity = shortCodeData[shortCode].community;
                    const shortCodeDataScript = shortCodeData[shortCode].script;
                    if (shortCodeDataConversation !== conversation
                        || shortCodeDataCommunity !== community
                        || shortCodeDataScript !== script
                    ) {
                        this.$router.push({name: 'Closed'});
                        return;
                    }
                }

                this.$cookies.set('data', {
                    [shortCode]: {
                        community, script, conversation,
                    },
                }, 2147483647);

                if (conversation && user) {
                    this.$router.push(`/${community}/chat/${script}?cid=${conversation}`);
                    return;
                }

                this.$router.push(`/${community}/chat/${script}/anonymous`);
            }).catch((e) => {
                if (e.response.status === 404) {
                    this.$router.push({name: 'Closed'});
                }
            });
    },
});
